import React, { SVGProps } from 'react';

const SvgPanoramaPhotosphere = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			fill='currentColor'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path
				d='M3 9.91v4.18C5.19 15.3 8.47 16 12 16c3.53 0 6.81-.69 9-1.91V9.91C18.81 8.7 15.53 8 12 8c-3.53 0-6.8.69-9 1.91z'
				opacity={0.3}
			/>
			<path d='M21.95 8.15c-.29-.16-.61-.31-.93-.46C19.4 4.33 15.98 2 12 2 8.02 2 4.6 4.33 2.99 7.68c-.33.15-.64.3-.93.46C1.41 8.5 1 9.17 1 9.91v4.18c0 .74.41 1.41 1.05 1.77.29.16.61.31.93.46C4.6 19.67 8.02 22 12 22c3.98 0 7.4-2.33 9.01-5.68.33-.15.64-.3.93-.46.65-.36 1.06-1.03 1.06-1.77V9.91c0-.74-.41-1.41-1.05-1.76zM12 4c2.37 0 4.49 1.04 5.95 2.68C16.17 6.25 14.15 6 12 6c-2.15 0-4.17.25-5.95.68A7.943 7.943 0 0112 4zm0 16c-2.37 0-4.49-1.04-5.95-2.68 1.78.43 3.8.68 5.95.68s4.17-.25 5.95-.68A7.943 7.943 0 0112 20zm9-10.09v4.18C18.81 15.31 15.53 16 12 16c-3.53 0-6.81-.7-9-1.91V9.91C5.2 8.69 8.47 8 12 8c3.53 0 6.81.7 9 1.91z' />
		</svg>
	);
}

export default SvgPanoramaPhotosphere;
