import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import user from './user/reducer';
import confirm from './confirm/reducer';
const rootReducer = combineReducers({
	user,
	confirm,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export type StoreTypes = ReturnType<typeof rootReducer>;

export default store;
