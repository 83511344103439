/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FirmData } from '../types';
import * as API from '../api/account';
import * as RamateAPI from '../api/ramateVerify';
import { useDispatch } from 'react-redux';
import useSWR from 'swr';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userData: Partial<FirmData>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('ramate_crm_auth') || '');

	const { data } = useSWR(
		user ? 'auth' : null,
		() => {
			const parseData = JSON.parse(user);
			// console.log('parseData', parseData);
			parseData.firmId === '' && parseData.token && parseData.userId
				? RamateAPI.adminAutoLogin({ ramateAccountId: parseData.userId, ramateAccountToken: parseData.token })
						.then((res) => {
							dispatch({ type: 'FIRM_ADMIN_LOGIN', payload: { ...res, deviceId: parseData.deviceId } });
						})
						.catch((err) => {
							if (err.status === 400) {
								dispatch({ type: 'FIRM_LOGOUT' });
							}
						})
				: API.autoLogin(parseData)
						.then((res) => {
							dispatch({
								type: 'FIRM_LOGIN',
								payload: {
									...res.data,
									deviceId: JSON.parse(user).deviceId,
									csrf: res.headers.get('Grpc-Metadata-Csrf-Token'),
									// CSRF token(模擬過期用)
									// csrf: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJtX2lkIjoiOGI3MGQ4MDAtYzVmNS00Y2FhLTkyOGMtMWIwN2Q0ZDIxNGUzIiwidXNlcl9pZCI6IjI0ODQ0YzViLTc5NzItNGI4Ni04MWZiLTdhZThmMjk5MTE3NSIsImF1ZCI6IjI0ODQ0YzViLTc5NzItNGI4Ni04MWZiLTdhZThmMjk5MTE3NSIsImV4cCI6MTY4MzUzNzQzMCwiaWF0IjoxNjgzNTM3MzcwLCJpc3MiOiJyYW1hdGV0ZWNoIn0.5S1Td9dIuzI7bD12WR-xWZHRd4j7DPPShnrGoIEuZ0s',
								},
							});
						})
						.catch((err) => {
							if (err.status === 400) {
								dispatch({ type: 'FIRM_LOGOUT' });
							} else if (err.status === 503) {
								// dispatch({ type: 'FIRM_LOGOUT' });
								window.location.reload();
							}
						});
		},
		{
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			shouldRetryOnError: false,
			errorRetryInterval: 0,
			errorRetryCount: 5,
		},
	);

	// const { data: adminData } = useSWR(
	// 	JSON.parse(user).firmInfo?.firmId === '' && JSON.parse(user).userInfo?.token && JSON.parse(user).userInfo?.userId
	// 		? 'authAdmin'
	// 		: null,
	// 	() =>
	// 		RamateAPI.adminAutoLogin(JSON.parse(user))
	// 			.then((res) => {
	// 				dispatch({ type: 'FIRM_ADMIN_LOGIN', payload: { ...res, deviceId: JSON.parse(user).deviceId } });
	// 			})
	// 			.catch((err) => {
	// 				console.log(user);
	// 				console.warn(err);
	// 				if (err.status === 400) {
	// 					console.warn('400 LOGOUT');
	// 					dispatch({ type: 'FIRM_LOGOUT' });
	// 				}
	// 			}),
	// 	{
	// 		revalidateOnFocus: false,
	// 		revalidateOnReconnect: false,
	// 		shouldRetryOnError: false,
	// 		errorRetryInterval: 0,
	// 		errorRetryCount: 5,
	// 	},
	// );
	const [userData, setUserData] = useState<Partial<FirmData>>({});
	const dispatch = useDispatch();

	// useEffect(() => {
	// 	if (user !== '') {
	// 		relogin();
	// 	}
	// }, [user]);

	// async function relogin() {
	// 	const req = JSON.parse(localStorage.getItem('ramate_crm_auth') || '');
	// 	API.autoLogin(req)
	// 		.then((res) => {
	// 			dispatch({ type: 'FIRM_LOGIN', payload: res });
	// 		})
	// 		.catch((err) => {
	// 			console.warn(err);
	// 			if (err.error.status === '400') {
	// 				dispatch({ type: 'FIRM_LOGOUT' });
	// 			}
	// 		});
	// }

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
		}),
		[user, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
