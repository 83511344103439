import React, { SVGProps } from 'react';

const SvgTimelapse = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			fill='currentColor'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M12 3.99c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm4.25 12.24c-2.35 2.34-6.15 2.34-8.49 0L12 11.99v-6c1.54 0 3.07.59 4.24 1.76a5.99 5.99 0 01.01 8.48z'
				opacity={0.3}
			/>
			<path d='M16.24 7.75A5.974 5.974 0 0012 5.99v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0a5.99 5.99 0 00-.01-8.48zM12 1.99c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' />
		</svg>
	);
}

export default SvgTimelapse;
