import React, { SVGProps } from 'react';

const SvgPrintDisabled = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			fill='currentColor'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0z' fill='none' />
			<path d='M7 10H5c-.55 0-1 .45-1 1v4h2v-2h4l-3-3z' opacity={0.3} />
			<path d='M1.41 1.6L0 3.01 5 8c-1.66 0-3 1.34-3 3v6h4v4h12l2.95 2.96 1.41-1.41L1.41 1.6zM6 15H4v-4c0-.55.45-1 1-1h2l3 3H6v2zm2 4v-4h4l4 4H8z' />
			<path
				d='M18 15.01l2-.01v-4c0-.55-.45-1-1-1h-6.34l3 3H18v2.01zm-1-3.5c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1z'
				opacity={0.3}
			/>
			<circle cx={18} cy={11.51} r={1} />
			<path d='M16 5H8v.35L10.66 8H16z' opacity={0.3} />
			<path d='M19 8h-1V3H6v.36l2 2V5h8v3h-5.34l2 2H19c.55 0 1 .45 1 1v4l-2 .01V13h-2.34l4 4H22v-6c0-1.66-1.34-3-3-3z' />
		</svg>
	);
}

export default SvgPrintDisabled;
