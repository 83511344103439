/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/default-param-last */
const initialState = {
	firmInfo: {
		firmId: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.firmId || '',
		name: '',
		imgUrl: '',
	},
	platformAuthority: {
		bombmy: false,
		bombcourse: false,
		bombmyAssociation: false,
	},
	roleAuthority: {
		order: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.roleAuthority?.order || 0,
		customer: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.roleAuthority?.customer || 0,
		firmInfo: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.roleAuthority?.firmInfo || 0,
		product: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.roleAuthority?.product || 0,
		admin: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.roleAuthority?.admin || 0,
	},
	userInfo: {
		userId: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.userId || '',
		userName: '',
		userPhone: '',
		userEmail: '',
		oneDeviceStatus: false,
		token: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.token || '',
		authorizationToken: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.authorizationToken || '',
		openPaginationStatus: false,
	},
	bombmyAuthority: {
		bombmyLive: 0,
		bombmySignup: 0,
		bombmySetting: 0,
		bombmyAssociation: 0,
	},
	deviceId: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.deviceId || '',
	csrf: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.csrf || '',
};
export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case 'FIRM_LOGIN': {
			state = action.payload;
			const storageData = {
				firmId: action.payload.firmInfo.firmId,
				userId: action.payload.userInfo.userId,
				token: action.payload.userInfo.token,
				deviceId: action.payload.deviceId,
				csrf: action.payload.csrf,
				authorizationToken: action.payload.userInfo.authorizationToken,
				roleAuthority: action.payload.roleAuthority,
			};

			localStorage.setItem('ramate_crm_auth', JSON.stringify(storageData));
			return { ...state };
		}

		case 'FIRM_ADMIN_LOGIN': {
			state.userInfo.token = action.payload.ramateAccountToken;
			state.userInfo.userId = action.payload.ramateAccountId;
			state.userInfo.userName = action.payload.ramateAccountName;
			state.roleAuthority.admin = 1;
			state.firmInfo.firmId = '';
			const storageData = {
				firmId: '',
				userId: action.payload.ramateAccountId,
				token: action.payload.ramateAccountToken,
				deviceId: action.payload.deviceId,
				roleAuthority: {
					order: 0,
					customer: 0,
					firmInfo: 0,
					product: 0,
					admin: 2,
				},
			};
			localStorage.setItem('ramate_crm_auth', JSON.stringify(storageData));
			return { ...state };
		}

		case 'FIRM_LOGOUT': {
			localStorage.clear();
			state = initialState;
			location.reload();
			return { ...state };
		}

		case 'FIRM_UPDATE_INFO': {
			state.userInfo.userName = action.payload.userName;
			state.userInfo.userPhone = action.payload.userPhone;
			state.userInfo.userEmail = action.payload.userEmail;

			state.userInfo.oneDeviceStatus = action.payload.oneDeviceStatus;
			state.userInfo.openPaginationStatus = action.payload.openPaginationStatus;
			return { ...state };
		}

		case 'ADMIN_UPDATE_INFO': {
			state.userInfo.userName = action.payload.ramateAccountName;
			state.userInfo.userPhone = action.payload.ramateAccountPhone;
			state.userInfo.userEmail = action.payload.ramateAccountEmail;
			return { ...state };
		}

		default:
			return state;
	}
}
