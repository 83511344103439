import { backendApi } from './base';
import {
	CrmCRMFirmAutoLoginRequest,
	CrmCRMFirmLoginRequest,
	CrmCRMFirmRegisterRequest,
	CrmCRMSignOutRequest,
	CrmSignOutAllDeviceRequest,
	CrmUpdateAccountInfoRequest,
} from '../swagger/backend';

export const login = async (req: CrmCRMFirmLoginRequest) => {
	const { data, headers } = await backendApi.firmLogin.crmFirmLogin(req);
	return { data, headers };
};

export const autoLogin = async (req: CrmCRMFirmAutoLoginRequest) => {
	const response = await backendApi.firmAutoLogin.crmFirmAutoLogin(req, {
		headers: {
			Accept: 'application/grpc, application/json',
		},
	});
	return response;
};

export const signOut = async (req: CrmCRMSignOutRequest) => {
	const { data } = await backendApi.signOut.crmSignOut(req);
	return data;
};

export const signOutAllDevice = async (req: CrmSignOutAllDeviceRequest) => {
	const { data } = await backendApi.signOutAllDevice.crmSignOutAllDevice(req);
	return data;
};

export const register = async (req: CrmCRMFirmRegisterRequest) => {
	const { data } = await backendApi.firmRegister.crmFirmRegister(req);
	return data;
};

export const updateAccountInfo = async (req: CrmUpdateAccountInfoRequest) => {
	const { data } = await backendApi.updateAccountInfo.crmUpdateAccountInfo(req);
	return data;
};
